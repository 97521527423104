const BusinessTypeOfContract = {
  DOMESTIC_DIGITAL_MUSIC: [1, 'Nhạc số trong nước'],
  INTERNATIONAL_DIGITAL_MUSIC: [2, 'Nhạc số quốc tế'],
  RING_TONE: [3, 'Nhạc chuông chờ'],
  BACKGROUND_MUSIC: [4, 'Nhạc nền (BGM)'],
  ZALO_MELODY: [5, 'Nhạc chờ Zalo'],
  ZSTYLE: [6, 'zStyle'],
};

export default BusinessTypeOfContract;
