import CONTAINER from './CONTAINER';
import ENDPOINTS from './ENDPOINTS';

const HOME = {
  GET: {
    GET: `${ENDPOINTS.entityGet(CONTAINER.HOME)}`,
  },
  POST: {},
};

const BUSSINESS_PARTNER = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.BUSSINESS_PARTNER)}`,
    ITEM: `${ENDPOINTS.entityGet(CONTAINER.BUSSINESS_PARTNER)}`,
    USERS: `${ENDPOINTS.entityGet(CONTAINER.BUSSINESS_PARTNER)}-users`,
    DELEGATES: `${ENDPOINTS.entityGet(CONTAINER.BUSSINESS_PARTNER)}-delegates`,
    MAPPING: `${ENDPOINTS.entityGet(CONTAINER.BUSSINESS_PARTNER)}-mapping`,
  },
  POST: {
    SET: `${ENDPOINTS.entitySet(CONTAINER.BUSSINESS_PARTNER)}`,
    SET_MAPPING: `${ENDPOINTS.entitySet(CONTAINER.BUSSINESS_PARTNER)}-mapping`,
    SET_PICK_DELEGATE: `${ENDPOINTS.entitySet(
      CONTAINER.BUSSINESS_PARTNER
    )}-pick-delegate`,
    ADD: `${ENDPOINTS.entityAdd(CONTAINER.BUSSINESS_PARTNER)}`,
  },
};
const OPTION = {
  GET: {
    BANKS: `${ENDPOINTS.entityGet(CONTAINER.OPTION)}-banks`,
    ACCOUNTS: `${ENDPOINTS.entityGet(CONTAINER.OPTION)}-accounts`,
    CONTRACTS: `${ENDPOINTS.entityGet(CONTAINER.OPTION)}-contracts`,
    ARTISTS: `${ENDPOINTS.entityGet(CONTAINER.OPTION)}-artists`,
    BUSINESS_PARTNERS: `${ENDPOINTS.entityGet(
      CONTAINER.OPTION
    )}-business-partners`,
    MEDIA_MAP: `${ENDPOINTS.entityGet(CONTAINER.OPTION)}-media-map`,
    SHARING_RATIO: `${ENDPOINTS.entityGet(CONTAINER.OPTION)}-sharing-ratio`,
    OLD_SHARING_RATIO: `${ENDPOINTS.entityGet(
      CONTAINER.OPTION
    )}-old-sharing-ratio`,
  },
};

const DELEGATE = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.DELEGATE)}`,
    ITEM: `${ENDPOINTS.entityGet(CONTAINER.DELEGATE)}`,
    USERS: `${ENDPOINTS.entityGet(CONTAINER.DELEGATE)}-users`,
    BUSINESS_PARTNER: `${ENDPOINTS.entityGet(
      CONTAINER.DELEGATE
    )}-business-partner`,
    MAPPING: `${ENDPOINTS.entityGet(CONTAINER.DELEGATE)}-mapping`,
  },
  POST: {
    SET: `${ENDPOINTS.entitySet(CONTAINER.DELEGATE)}`,
    SET_MAPPING: `${ENDPOINTS.entitySet(CONTAINER.DELEGATE)}-mapping`,
    ADD: `${ENDPOINTS.entityAdd(CONTAINER.DELEGATE)}`,
  },
};
const CONTRACT = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.CONTRACT)}`,
    PAG_ADDENDUM: `${ENDPOINTS.entityPag(CONTAINER.CONTRACT)}-addendum`,
    ITEM: `${ENDPOINTS.entityGet(CONTAINER.CONTRACT)}`,
    FILE: `${ENDPOINTS.entityGet(CONTAINER.CONTRACT)}-file`,
  },
  POST: {
    SET: `${ENDPOINTS.entitySet(CONTAINER.CONTRACT)}`,
    SET_MUL_STATUS: `${ENDPOINTS.entitySetMul(CONTAINER.CONTRACT)}-status`,
    ADD: `${ENDPOINTS.entityAdd(CONTAINER.CONTRACT)}`,
  },
};
const ARTIST = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.ARTIST)}`,
    ITEM: `${ENDPOINTS.entityGet(CONTAINER.ARTIST)}`,
  },
  POST: {
    SET: `${ENDPOINTS.entitySet(CONTAINER.ARTIST)}`,
    ADD: `${ENDPOINTS.entityAdd(CONTAINER.ARTIST)}`,
  },
};
const MEDIA = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.MEDIA)}`,
    ITEM: `${ENDPOINTS.entityGet(CONTAINER.MEDIA)}`,
  },
  POST: {
    SET: `${ENDPOINTS.entitySet(CONTAINER.MEDIA)}`,
    ADD: `${ENDPOINTS.entityAdd(CONTAINER.MEDIA)}`,
  },
};

const ADDENDUM = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.ADDENDUM)}`,
    ITEM: `${ENDPOINTS.entityGet(CONTAINER.ADDENDUM)}`,
    MEDIAS: `${ENDPOINTS.entityGet(CONTAINER.ADDENDUM)}-medias`,
    PAG_MEDIA: `${ENDPOINTS.entityGet(CONTAINER.ADDENDUM)}-pag-media`,
    FILE: `${ENDPOINTS.entityGet(CONTAINER.ADDENDUM)}-file`,
  },
  POST: {
    SET: `${ENDPOINTS.entitySet(CONTAINER.ADDENDUM)}`,
    ADD: `${ENDPOINTS.entityAdd(CONTAINER.ADDENDUM)}`,
    SET_MEDIAS: `${ENDPOINTS.entitySet(CONTAINER.ADDENDUM)}-medias`,
    SET_MEDIAS_STATUS: `${ENDPOINTS.entitySet(
      CONTAINER.ADDENDUM
    )}-medias-status`,
    SET_MEDIA_RIGHTS: `${ENDPOINTS.entitySet(CONTAINER.ADDENDUM)}-media-rights`,
    DEL_MEDIAS: `${ENDPOINTS.entityDel(CONTAINER.ADDENDUM)}-medias`,
    SET_MEDIA_SHARING_RATIO: `${ENDPOINTS.entitySet(
      CONTAINER.ADDENDUM
    )}-media-sharing-ratio`,
    SET_MEDIA_END_BUSINESS: `${ENDPOINTS.entitySet(
      CONTAINER.ADDENDUM
    )}-media-end-business-time`,
  },
};

const EXPORT = (container) => {
  return {
    GET: {
      PAG: `${ENDPOINTS.entityGet(container)}-jobs`,
      PREVIEW: `${ENDPOINTS.entityGet(container)}-data-preview`,
      HEADERS: `${ENDPOINTS.entityGet(container)}-headers`,
      FILE: `${ENDPOINTS.entityGet(container)}-file-download`,
    },
    POST: {
      ADD: `${ENDPOINTS.entityAdd(container)}-job`,
      KILL: `${ENDPOINTS.entitySet(container)}-kill-job`,
      RESUME: `${ENDPOINTS.entitySet(container)}-resume-job`,
    },
  };
};
const IMPORT = (container) => {
  return {
    GET: {
      PAG: `${ENDPOINTS.entityGet(container)}-jobs`,
      HEADERS: `${ENDPOINTS.entityGet(container)}-headers`,
      FILE: `${ENDPOINTS.entityGet(container)}-file-download`,
    },
    POST: {
      ADD: `${ENDPOINTS.entityAdd(container)}-job`,
      KILL: `${ENDPOINTS.entitySet(container)}-kill-job`,
      RESUME: `${ENDPOINTS.entitySet(container)}-resume-job`,
    },
  };
};
const REVENUE_SETTINGS = {
  GET: {
    PAG: `${ENDPOINTS.entityGet(CONTAINER.REVENUE_SETTINGS)}-jobs`,
    HEADERS: `${ENDPOINTS.entityGet(CONTAINER.REVENUE_SETTINGS)}-headers`,
    SETTINGS: `${ENDPOINTS.entityGet(CONTAINER.REVENUE_SETTINGS)}-settings`,
    JOB: `${ENDPOINTS.entityGet(CONTAINER.REVENUE_SETTINGS)}-job`,
    FILE: `${ENDPOINTS.entityGet(CONTAINER.REVENUE_SETTINGS)}-file-download`,
  },
  POST: {
    SET_SETTING: `${ENDPOINTS.entitySet(CONTAINER.REVENUE_SETTINGS)}-setting`,
    SET_JOB: `${ENDPOINTS.entitySet(CONTAINER.REVENUE_SETTINGS)}-job`,
    SET_PUBLIC: `${ENDPOINTS.entitySet(CONTAINER.REVENUE_SETTINGS)}-public`,
    SET_SETTING_JOB: `${ENDPOINTS.entitySet(
      CONTAINER.REVENUE_SETTINGS
    )}-setting-job`,
    KILL: `${ENDPOINTS.entitySet(CONTAINER.REVENUE_SETTINGS)}-kill-job`,
    RESUME: `${ENDPOINTS.entitySet(CONTAINER.REVENUE_SETTINGS)}-resume-job`,
  },
};

const BUSINESS_PARTNER_REVENUE = {
  GET: {
    LIST: `${ENDPOINTS.entityGet(CONTAINER.BUSSINESS_PARTNER_REVENUE)}-list`,
    TOTAL: `${ENDPOINTS.entityGet(CONTAINER.BUSSINESS_PARTNER_REVENUE)}-total`,
  },
  POST: {},
};
const DEBT_MG = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.DEBT_MG)}`,
  },
  POST: {},
};
const DEBT_MG_ZINGCHART = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.DEBT_MG_ZINGCHART)}`,
  },
  POST: {},
};
const CONTRACT_REVENUE = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.CONTRACT_REVENUE)}`,
    TOTAL: `${ENDPOINTS.entityGet(CONTAINER.CONTRACT_REVENUE)}-total`,
  },
  POST: {},
};
const MEDIA_REVENUE = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.MEDIA_REVENUE)}`,
    TOTAL: `${ENDPOINTS.entityGet(CONTAINER.MEDIA_REVENUE)}-total`,
  },
  POST: {},
};

const INVALID_MEDIA_REVENUE = {
  GET: {
    PAG: `${ENDPOINTS.entityPag(CONTAINER.INVALID_MEDIA_REVENUE)}`,
    TOTAL: `${ENDPOINTS.entityGet(CONTAINER.INVALID_MEDIA_REVENUE)}-total`,
  },
  POST: {},
};

const API_PATHS = {
  HOME,
  BUSSINESS_PARTNER,
  OPTION,
  DELEGATE,
  CONTRACT,
  ARTIST,
  MEDIA,
  ADDENDUM,
  EXPORT,
  IMPORT,
  REVENUE_SETTINGS,
  BUSINESS_PARTNER_REVENUE,
  DEBT_MG,
  DEBT_MG_ZINGCHART,
  CONTRACT_REVENUE,
  MEDIA_REVENUE,
  INVALID_MEDIA_REVENUE,
};
export default API_PATHS;
