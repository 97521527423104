import { EnumSelectbox, SelectSuggest } from '@cms/tpl';
import { Input } from 'antd';
import { CONTAINER } from 'common';
import MonthPicker from 'components/monthpicker/MonthPicker';
import ENUMS from 'enums';
import React from 'react';
import OptionService from 'services/OptionService';
import { COMMON_ELEMENTS } from '../Utils';
import ExportCommonIndex from '../index';
const container = CONTAINER.EXPORT_INVALID_MEDIA_REVENUE;
const elements = () => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Tháng bắt đầu',
          name: 'monthFrom',
          input: <MonthPicker />,
          rules: [{ required: true }],
        },
        {
          label: 'Tháng kết thúc',
          name: 'monthTo',
          input: <MonthPicker />,
          rules: [{ required: true }],
        },
        {
          label: 'Kênh phân phối',
          name: 'distributeType',
          input: <EnumSelectbox enums={ENUMS.DistributeType} />,
        },
        {
          label: 'Đối tác',
          name: 'businessPartnerId',
          input: (
            <SelectSuggest suggestApi={OptionService.getBusinessPartners} />
          ),
        },
        {
          label: 'Số hợp đồng',
          name: 'contractId',
          input: <SelectSuggest suggestApi={OptionService.getContracts} />,
        },
        {
          label: 'Tên bài hát',
          name: 'title',
          input: <Input />,
        },
        {
          label: 'Nghệ sĩ',
          name: 'artistId',
          input: <SelectSuggest suggestApi={OptionService.getArtists} />,
        },
      ],
    },
    COMMON_ELEMENTS(container, true),
  ];
  return [{ cols }];
};
const ExportInvalidMediaRevenueIndex = () => {
  return (
    <ExportCommonIndex
      formElements={elements()}
      title={'Doanh thu bài hát'}
      container={container}
      hidePreview
    />
  );
};

export default ExportInvalidMediaRevenueIndex;
