const CONTAINER = {
  HOME: 'home',
  OPTION: 'option',
  BUSSINESS_PARTNER: 'business-partner',
  DELEGATE: 'delegate',
  ADDENDUM: 'addendum',
  ARTIST: 'artist',
  MEDIA: 'media',
  CONTRACT: 'contract',
  EXPORT_CONTRACT: 'export-contract',
  EXPORT_ADDENDUM: 'export-addendum',
  EXPORT_BUSINESS_PARTNER: 'export-business-partner',
  REVENUE_SETTINGS: 'revenue-settings',
  BUSSINESS_PARTNER_REVENUE: 'business-partner-revenue',
  DEBT_MG: 'debt-mg',
  DEBT_MG_ZINGCHART: 'debt-mg-zingchart',
  CONTRACT_REVENUE: 'contract-revenue',
  MEDIA_REVENUE: 'media-revenue',
  INVALID_MEDIA_REVENUE: 'invalid-media-revenue',
  EXPORT_REVENUE_ADS: 'export-revenue-ads',
  EXPORT_REVENUE_VIP: 'export-revenue-vip',
  EXPORT_REVENUE_PUBLIC_STREAMING: 'export-revenue-public-streaming',
  EXPORT_REVENUE_ORCHARD: 'export-revenue-orchard',
  EXPORT_REVENUE_RINGTONE: 'export-revenue-ringtone',
  EXPORT_CONTRACT_REVENUE: 'export-contract-revenue',
  EXPORT_MEDIA_REVENUE: 'export-media-revenue',
  EXPORT_INVALID_MEDIA_REVENUE: 'export-invalid-media-revenue',
  EXPORT_REVENUE_ZMELODY: 'export-revenue-zmelody',
  EXPORT_REVENUE_ZSTYLE: 'export-revenue-zstyle',
  EXPORT_DEBT_MG: 'export-debt-mg',
  EXPORT_DEBT_MG_ZINGCHART: 'export-debt-mg-zingchart',
  IMPORT_CONTRACT: 'import-contract',
  IMPORT_MEDIA: 'import-media',
  IMPORT_ADDENDUM_MEDIA: 'import-addendum-media',
  EXPORT_MEDIA: 'export-media',
};
export default CONTAINER;
