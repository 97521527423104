import {
  CustomerServiceOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import {
  DateRangePicker,
  EnumSelectbox,
  FormFilter,
  Portlet,
  PortletTable,
  SelectSuggest,
} from '@cms/tpl';
import { Button, Card, Col, Form, Input, Row, Statistic } from 'antd';
import { API_PATHS, CONTAINER, ICONS, LINKS } from 'common';
import ENUMS from 'enums';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import UTILS from 'utils';
import FORMAT from 'utils/Format';
import Service from './Service';

const InvalidMediaRevenueIndex = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  const [total, setTotal] = useState({ totalPlay: 0, totalRevenue: 0 });
  const defaultMonth = moment().unix();
  const [monthSetted, setMonthSetted] = useState(false);
  useEffect(() => {
    Service.getTotal(location.search).then((resp) => {
      // @ts-ignore
      setTotal(resp.item);
    });
    if (location.search.includes('month=')) {
      setMonthSetted(true);
    }
  }, [location.search]);

  const renderMediaTable = () => {
    if (!monthSetted) {
      return null;
    }

    return (
      <PortletTable
        title='Danh sách Media'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        hideSelectedRow
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.INVALID_MEDIA_REVENUE.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS}
        extendedAction={[
          <Button
            type='ghost'
            icon={ICONS.UploadOutlined}
            onClick={() => {
              const urlSearchParams = new URLSearchParams(
                window.location.search
              );
              const monthValue = urlSearchParams.get('month').split(',');
              urlSearchParams.delete('month');
              urlSearchParams.set(
                'monthFrom',
                moment(Number(monthValue[0]) * 1000).format('YYYYMM')
              );
              urlSearchParams.set(
                'monthTo',
                moment(Number(monthValue[1]) * 1000).format('YYYYMM')
              );
              urlSearchParams.append('tab', '1');
              navigate(
                `/${
                  CONTAINER.EXPORT_INVALID_MEDIA_REVENUE
                }?${urlSearchParams.toString()}`
              );
            }}
          >
            Xuất dữ liệu
          </Button>,
        ]}
      />
    );
  };

  return (
    <React.Fragment>
      <Row className='stats-row' gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title={`Tổng doanh thu chia sẻ`}
              value={total.totalRevenue}
              valueStyle={{ color: 'rgb(34, 145, 169)' }}
              prefix={<MoneyCollectOutlined />}
              suffix='VNĐ'
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={`Tổng lượt nghe`}
              value={total.totalPlay}
              valueStyle={{ color: 'rgb(147, 28, 143)' }}
              prefix={<CustomerServiceOutlined />}
              suffix=''
            />
          </Card>
        </Col>
      </Row>
      <Portlet title='Lọc Media' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch={false}
          name='form-filter'
          form={form}
          initialValues={{ month: [defaultMonth, defaultMonth] }}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      {renderMediaTable()}
    </React.Fragment>
  );
};

const FILTERS = [
  {
    label: 'Tháng',
    name: 'month',
    // input: <MonthPicker />,
    input: <DateRangePicker picker='month' format={'YYYYMM'} />,
    rules: [{ required: true }],
  },
  {
    label: 'Mixus ID',
    name: 'mediaId',
    input: <Input />,
  },
  {
    label: 'Kênh phân phối',
    name: 'distributeType',
    input: <EnumSelectbox enums={ENUMS.DistributeType} />,
  },
  {
    label: 'Số hợp đồng',
    name: 'contractNumber',
    input: <Input />,
  },
  {
    label: 'Đối tác',
    name: 'businessPartnerId',
    input: (
      <SelectSuggest
        allowSearchEmpty
        suggestApi={OptionService.getBusinessPartners}
      />
    ),
  },
  {
    label: 'Tên bài hát',
    name: 'title',
    input: <Input placeholder='' />,
  },
  {
    label: 'Ca sĩ',
    name: 'artistId',
    input: <SelectSuggest suggestApi={OptionService.getArtists} />,
  },
];
const COLUMNS = [
  {
    title: 'Tháng',
    dataIndex: 'month',
  },
  {
    title: 'Phân phối',
    dataIndex: 'distributeType',
    render: (value) => UTILS.ENUM.findByValue(ENUMS.DistributeType, value, 2),
  },
  {
    title: 'Mixus ID',
    dataIndex: 'mediaId',
  },
  {
    title: 'Tên bài hát',
    dataIndex: ['media', 'title'],
  },
  {
    title: 'Nghệ sĩ',
    dataIndex: 'artistName',
  },
  {
    title: 'Số hợp đồng',
    dataIndex: 'contractNumber',
    render: (contractNumber, entry) => {
      return (
        <Link to={LINKS.toDetail(CONTAINER.CONTRACT, entry.contractId)}>
          {contractNumber}
        </Link>
      );
    },
  },
  {
    title: 'Số phụ lục',
    dataIndex: 'addendumNumber',
    render: (addendumNumber, entry) => {
      return (
        <Link to={LINKS.toDetail(CONTAINER.ADDENDUM, entry.addendumId)}>
          {addendumNumber}
        </Link>
      );
    },
  },
  {
    title: 'Đối tác',
    dataIndex: ['businessPartner', 'legalName'],
    render: (legalName, entry) => {
      return (
        <Link
          to={LINKS.toDetail(
            CONTAINER.BUSSINESS_PARTNER,
            entry.businessPartnerId
          )}
        >
          {legalName}
        </Link>
      );
    },
  },
  {
    title: 'Lượt nghe',
    dataIndex: 'totalPlay',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Doanh thu',
    dataIndex: 'totalRevenue',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Tiền tệ',
    dataIndex: '',
    render: () => 'VNĐ',
  },
  {
    title: 'Lý do',
    dataIndex: 'reasonNote',
  },
];

export default InvalidMediaRevenueIndex;
