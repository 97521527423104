import AddendumIndex from 'pages/addendum';
import AddendumDetail from 'pages/addendum/detail';
import ArtistIndex from 'pages/artist';
import BusinessPartnerIndex from 'pages/businesspartner';
import BusinessPartnerDetail from 'pages/businesspartner/detail';
import BusinessPartnerRevenueIndex from 'pages/businesspartnerrevenue';
import ContractIndex from 'pages/contract';
import ContractDetail from 'pages/contract/detail';
import ContractRevenueIndex from 'pages/contractrevenue';
import DebtMGIndex from 'pages/debtmg';
import DebtMGZingchartIndex from 'pages/debtmgzingchart';
import DelegateIndex from 'pages/delegate';
import DelegateDetail from 'pages/delegate/detail';
import ExportAddendumIndex from 'pages/exports/exportaddendum';
import ExportBusinessPartnerIndex from 'pages/exports/exportbusinesspartner';
import ExportContractIndex from 'pages/exports/exportcontract';
import ExportContractRevenueIndex from 'pages/exports/exportcontractrevenue';
import ExportDebtMGIndex from 'pages/exports/exportdebtmg';
import ExportDebtMGZingchartIndex from 'pages/exports/exportdebtmgzingchart';
import ExportInvalidMediaRevenueIndex from 'pages/exports/exportinvalidmediarevenue';
import ExportMediaIndex from 'pages/exports/exportmedia';
import ExportMediaRevenueIndex from 'pages/exports/exportmediarevenue';
import ExportRevenueAdsIndex from 'pages/exports/exportrevenueads';
import ExportRevenueOrchardIndex from 'pages/exports/exportrevenueorchard';
import ExportRevenuePublicStreamingIndex from 'pages/exports/exportrevenuepublicstreaming';
import ExportRevenueRingtoneIndex from 'pages/exports/exportrevenueringtone';
import ExportRevenueVipIndex from 'pages/exports/exportrevenuevip';
import ExportRevenueZMelodyIndex from 'pages/exports/exportrevenuezmelody';
import ExportRevenueZStyleIndex from 'pages/exports/exportrevenuezstyle';
import ImportAddendumMediaIndex from 'pages/imports/importaddendummedia';
import ImportContractIndex from 'pages/imports/importcontract';
import ImportMediaIndex from 'pages/imports/importmedia';
import InvalidMediaRevenueIndex from 'pages/invalidmediarevenue';
import MediaIndex from 'pages/media';
import MediaRevenueIndex from 'pages/mediarevenue';
import RevenueSettingsIndex from 'pages/revenuesettings';
import React from 'react';
import Home from '../pages/Home';

const PAGES = {
  Home: <Home />,
  BusinessPartnerIndex: <BusinessPartnerIndex />,
  BusinessPartnerDetail: <BusinessPartnerDetail />,
  BusinessPartnerCreate: <BusinessPartnerDetail created />,
  ContractIndex: <ContractIndex />,
  ContractDetail: <ContractDetail />,
  ContractCreate: <ContractDetail created />,
  AddendumIndex: <AddendumIndex />,
  AddendumDetail: <AddendumDetail />,
  AddendumCreate: <AddendumDetail created />,
  DelegateIndex: <DelegateIndex />,
  DelegateDetail: <DelegateDetail />,
  DelegateCreate: <DelegateDetail created />,
  ArtistIndex: <ArtistIndex />,
  MediaIndex: <MediaIndex />,
  BusinessPartnerRevenueIndex: <BusinessPartnerRevenueIndex />,
  ContractRevenueIndex: <ContractRevenueIndex />,
  MediaRevenueIndex: <MediaRevenueIndex />,
  DebtMGIndex: <DebtMGIndex />,
  DebtMGZingchartIndex: <DebtMGZingchartIndex />,
  ExportContractIndex: <ExportContractIndex />,
  ExportAddendumIndex: <ExportAddendumIndex />,
  ExportBusinessPartnerIndex: <ExportBusinessPartnerIndex />,
  RevenueSettingsIndex: <RevenueSettingsIndex />,
  ExportRevenueAdsIndex: <ExportRevenueAdsIndex />,
  ExportRevenueVipIndex: <ExportRevenueVipIndex />,
  ExportRevenuePublicStreamingIndex: <ExportRevenuePublicStreamingIndex />,
  ExportRevenueRingtoneIndex: <ExportRevenueRingtoneIndex />,
  ExportRevenueOrchardIndex: <ExportRevenueOrchardIndex />,
  ExportRevenueZMelodyIndex: <ExportRevenueZMelodyIndex />,
  ExportRevenueZStyleIndex: <ExportRevenueZStyleIndex />,
  ExportContractRevenueIndex: <ExportContractRevenueIndex />,
  ExportMediaRevenueIndex: <ExportMediaRevenueIndex />,
  ExportInvalidMediaRevenueIndex: <ExportInvalidMediaRevenueIndex />,
  ExportDebtMGIndex: <ExportDebtMGIndex />,
  ExportDebtMGZingchartIndex: <ExportDebtMGZingchartIndex />,
  ImportContractIndex: <ImportContractIndex />,
  ExportMediaIndex: <ExportMediaIndex />,
  ImportAddendumMediaIndex: <ImportAddendumMediaIndex />,
  ImportMediaIndex: <ImportMediaIndex />,
  InvalidMediaRevenueIndex: <InvalidMediaRevenueIndex />,
};

export default PAGES;
